"use strict"

const navToggle = document.getElementById('navToggle')
const navibar = document.getElementById('navibar')

navToggle.addEventListener('click', (e) => {
	e.preventDefault();

	// Open navibar
	if ( !navibar.classList.contains('opened') ) {
		let navibarHeight = navibar.scrollHeight
		navibar.classList.add('opened')
		navibar.style.maxHeight = navibarHeight + 'px'

		navToggle.classList.add('close')

		return
	}

	// Hide navibar
	navibar.classList.remove('opened')
	navibar.style.maxHeight = ''
	navToggle.classList.remove('close')

	
}, false)